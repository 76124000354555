<template>
  <div>
    <v-overlay :value="overlay"><v-progress-circular :size="70" :width="7" color="green"
        indeterminate></v-progress-circular></v-overlay>
    <v-container fluid class="px-6 py-6">
      <v-row>
        <v-col cols="12">
          <v-data-table :headers="headers" :items="blendPlans" :items-per-page="5" class="elevation-1" disable-pagination
            :hide-default-footer="true">
            <template v-slot:top>
              <v-toolbar flat>
                <!-- <v-toolbar-title> Day-{{ idx + 1 }}</v-toolbar-title> -->
                <v-spacer></v-spacer>
                <!-- <v-btn
                  @click="SyncMo"
                  elevation="0"
                  :ripple="false"
                  height="43"
                  dark
                  class="font-weight-bold text-capitalize btn-ls btn-secondary bg-light px-6 mr-5"
                  color="blue"
                  >Sync</v-btn
                > -->
              </v-toolbar>
            </template>
            <template v-slot:[`item.productionDate`]="{ item }">
              <span>
                {{ dateFormater(item.productionDate) }}
              </span>
            </template>

            <template v-slot:[`item.mo_number`]="{ item }">
              <v-chip label small :ripple="false" color="rgba(105, 240, 174, 0.5)"
                v-if="item.status === 'SUBMIT' && item.mo_number">
                {{ item.mo_number }}
              </v-chip>
              <v-chip label small :ripple="false" color="rgba(255, 110, 64, 0.5)"
                v-if="item.status === 'NEW' || !item.mo_number">
                Pending
              </v-chip>
              <!-- <v-chip label small :ripple="false" color="rgba(255, 245, 157, 0.8)" v-if="item.status === 'DRAFT'"> Draft </v-chip> -->
            </template>

            <template v-slot:[`item.status`]="{ item }">
              <!-- <v-btn
                text
                class="pe-4 ps-0 mb-0 text-default text-capitalize icon-move-right align-center"
                v-if="item.status == 'NEW'"
                @click="goToNext('NEW', item)"
              >
                Create
                <v-icon right dark size="12" class="ms-0 mt-1"> fa fa-arrow-right </v-icon>
              </v-btn> -->
              <!-- <v-btn
                text
                class="pe-4 ps-0 mb-0 text-default text-capitalize icon-move-right align-center"
                v-if="item.status == 'SUBMIT'"
                @click="goToNext('SUBMIT', item)"
              >
                View
                <v-icon right dark size="12" class="ms-0 mt-1"> fa fa-arrow-right </v-icon>
              </v-btn> -->
              <!-- <v-btn
                text
                class="pe-4 ps-0 mb-0 text-default text-capitalize icon-move-right align-center"
                v-if="item.status == 'DRAFT'"
                @click="goToNext('DRAFT', item)"
              >
                Edit
                <v-icon right dark size="12" class="ms-0 mt-1"> fa fa-arrow-right </v-icon>
              </v-btn> -->
              <v-btn text class="pe-4 ps-0 mb-0 text-default text-capitalize icon-move-right align-center"
                @click="goToNext('SUBMIT', item)">
                View
                <v-icon right dark size="12" class="ms-0 mt-1"> fa fa-arrow-right </v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import formatDate from "../../../../global-data/dateFormatter";

import BlendApi from "../../blendSheet/api";
import api from "../api";
export default {
  data() {
    return {
      overlay: false,
      headers: [
        {
          text: "Blend Plan",
          value: "name",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",

          sortable: true,
        },
        {
          text: "Source Number",
          value: "source_number",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",

          sortable: true,
        },
        {
          text: "Production Date",
          value: "productionDate",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",

          sortable: true,
        },
        {
          text: "Production QTY",
          value: "productionQuantity",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",

          sortable: true,
        },
        {
          text: "Code",
          value: "blendCodeName",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",

          sortable: true,
        },
        {
          text: "Type",
          value: "blend_type",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",

          sortable: true,
        },
        {
          text: "Warehouse",
          value: "companyWarehouseName",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",

          sortable: true,
        },
        {
          text: "MO",
          value: "mo_number",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",

          sortable: true,
        },
        {
          text: "Action",
          value: "status",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",

          sortable: true,
        },
      ],
    };
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      this.overlay = true;

      this.blendPlans = await api.getBlendPan_Mo();
      // // Api From Blend
      // this.blendPlans = await BlendApi.getApprovedPlans();
      // let b = [];
      // this.blendPlans.forEach((element) => {
      //   b.push(element.name);
      // });

      // await api.getMoStatus(b).then((res) => {
      //   this.blendPlans.forEach((el) => {
      //     let indx = res.findIndex((elm) => el.name == elm.blend_plan);
      //     if (indx > -1) {
      //       el.mo_id = res[indx].id;
      //       el.status = res[indx].status;
      //     } else {
      //       el.status = "NEW";
      //     }
      //   });
      // });

      this.overlay = false;
    },
    dateFormater(date) {
      return formatDate.formatDate(date);
    },
    async SyncMo() {
      this.overlay = true;
      await api.SyncMo().then((res) => {
        if (res.status == 201) {
          this.showSuccessAlert(res.message);
        }
        if (res.status == 401) this.showErrorAlert(res.message);
      });
      this.overlay = false;
    },
    goToNext(type, item, id = 0) {
      // console.log({
      //   type,
      //   id,
      //   blendPlan: item,
      // });
      this.$router.push({
        name: "MODetails",
        params: {
          type,
          id,
          blendPlan: item,
        },
      });
    },
    showSuccessAlert(message) {
      this.$swal({
        title: "Success",
        text: message,
        type: "success",
        timer: 3000,
        icon: "success",
        showConfirmButton: false,
      });
    },

    showErrorAlert(message) {
      this.$swal({
        title: "Error",
        text: message,
        type: "error",

        icon: "error",
        showConfirmButton: true,
      });
    },
  },
};
</script>
<style scoped></style>
