import axios from "axios";

export default {
  async saveAsDraft(data) {
    return await axios
      .post(`MO/save-as-draft`, data)
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async submitMo(data) {
    return await axios
      .post(`MO/submit-mo`, data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async SyncMo(data) {
    return await axios
      .get(`MO/mo-sync`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async getBlendPan_Mo() {
    return await axios
      .get(`MO/mo-status`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async getMoDetails(blend_plan) {
    return await axios
      .get(`MO/mo-details`, { params: { blend_plan } })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async getBlendPlanDetails(id) {
    return await axios
      .get(`blend/blend-plan-details/` + id)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
};
